var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailView"},[_c('div',{staticClass:"pageContent-title"},[_vm._v("服务内容")]),_c('div',{staticClass:"mt-5 detailView-with-actions"},[_c('a-form',{staticClass:"flex-form form-label-5",attrs:{"form":_vm.form}},[_c('div',{staticClass:"form-base-width"},[_c('a-form-item',{attrs:{"label":"条线"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'lineCode',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]),expression:"[\n              'lineCode',\n              {\n                rules: [{ required: true, message: '请选择' }],\n              },\n            ]"}],staticClass:"form-control",attrs:{"options":_vm.opsLineList,"placeholder":"请选择"},on:{"change":_vm.onSelectOpsLine}})],1),_c('a-form-item',{attrs:{"label":"类别"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'typeCode',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]),expression:"[\n              'typeCode',\n              {\n                rules: [{ required: true, message: '请选择' }],\n              },\n            ]"}],staticClass:"form-control",attrs:{"options":_vm.opsTypeList,"placeholder":"请选择"}})],1),_c('a-form-item',{attrs:{"label":"服务名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'opsName',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]),expression:"[\n              'opsName',\n              {\n                rules: [{ required: true, message: '请输入' }],\n              },\n            ]"}],attrs:{"placeholder":"请输入服务名称"}})],1),_c('div',{staticClass:"grid grid-cols-3 gap-5"},[_c('a-form-item',{attrs:{"label":"发布日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['publishTime']),expression:"['publishTime']"}],attrs:{"placeholder":"请选择发布日期"}})],1)],1),_c('a-form-item',{attrs:{"label":"场景描述"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sceneNote']),expression:"['sceneNote']"}],attrs:{"autoSize":{ minRows: 10, maxRows: 15 },"placeholder":"请输入场景描述"}})],1),_c('a-form-item',{attrs:{"label":"请求地址"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'serviceUrl',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]),expression:"[\n              'serviceUrl',\n              {\n                rules: [{ required: true, message: '请输入' }],\n              },\n            ]"}],attrs:{"placeholder":"请输入请求地址"}})],1),_c('a-form-item',{attrs:{"label":"请求方式"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'serviceType',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]),expression:"[\n              'serviceType',\n              {\n                rules: [{ required: true, message: '请选择' }],\n              },\n            ]"}],staticClass:"form-control",attrs:{"options":_vm.serviceTypeList,"placeholder":"请选择请求方式"}})],1),_c('a-form-item',{attrs:{"label":"登记json"}},[_c('JsonEditor',{staticStyle:{"width":"100%","height":"500px"},attrs:{"json":_vm.json},on:{"change":_vm.editorChange}}),_c('div',[(_vm.errorMsg)?_c('div',{staticStyle:{"color":"#f5222d"}},[_vm._v(_vm._s(_vm.errorMsg))]):_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"flex justify-end gap-2"},[_c('a-button',{attrs:{"type":"primary","shape":"circle"},on:{"click":_vm.addRow}},[_c('a-icon',{attrs:{"type":"plus-circle"}})],1),_c('a-button',{attrs:{"type":"primary","shape":"circle","ghost":"","disabled":_vm.selectedRowKeys.length === 0},on:{"click":_vm.minusRow}},[_c('a-icon',{attrs:{"type":"minus-circle"}})],1)],1),_c('a-table',{staticClass:"mt-2",attrs:{"row-selection":{ selectedRowKeys: _vm.selectedRowKeys, onChange: _vm.onSelectChange },"dataSource":_vm.json,"columns":_vm.columns,"size":"small","pagination":false}})],1)])],1),_c('a-form-item',{attrs:{"label":"在线文档"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['fileUrl']),expression:"['fileUrl']"}],attrs:{"placeholder":"请输入文档地址"}})],1),(_vm.type === 'audit')?_c('a-form-item',{attrs:{"label":"状态"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'status',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]),expression:"[\n              'status',\n              {\n                rules: [{ required: true, message: '请选择' }],\n              },\n            ]"}],attrs:{"options":_vm.typesMap.status}})],1):_vm._e()],1)]),_c('div',{staticClass:"detailView-actions"},[_c('a-button',{staticClass:"app-btn condition-btns",attrs:{"type":"primary"},on:{"click":_vm.doSave}},[_vm._v("保存")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }