<template>
  <div class="jsoneditor-container" ref="jeContainer" />
</template>

<script>
import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'
export default {
  props: {
    json: {
      type: [Object, Array],
      require: true,
    },
  },
  data() {
    return {}
  },
  watch: {
    json: {
      deep: true,
      handler(val) {
        if (JSON.stringify(val) !== JSON.stringify(this.jsoneditor.get())) {
          this.jsoneditor.update(val)
        }
      },
    },
  },
  methods: {
    onChange(a, b, c) {
      try {
        const json = this.jsoneditor.get()
        this.$emit('change', null, json)
      } catch (e) {
        this.$emit('change', e)
      }
    },
  },
  destroyed() {
    if (this.jsoneditor) {
      this.jsoneditor.destroy()
    }
  },
  updated() {
    this.jsoneditor.update(this.json)
  },
  mounted() {
    const options = {
      mode: 'code',
      onChange: this.onChange,
    }
    this.jsoneditor = new JSONEditor(this.$refs.jeContainer, options)
    this.jsoneditor.set(this.json)
  },
}
</script>

<style></style>
