<template>
  <div class="detailView">
    <div class="pageContent-title">服务内容</div>
    <div class="mt-5 detailView-with-actions">
      <a-form class="flex-form form-label-5" :form="form">
        <div class="form-base-width">
          <a-form-item label="条线">
            <a-select
              class="form-control"
              v-decorator="[
                'lineCode',
                {
                  rules: [{ required: true, message: '请选择' }],
                },
              ]"
              :options="opsLineList"
              placeholder="请选择"
              @change="onSelectOpsLine"
            ></a-select>
          </a-form-item>
          <a-form-item label="类别">
            <a-select
              class="form-control"
              :options="opsTypeList"
              v-decorator="[
                'typeCode',
                {
                  rules: [{ required: true, message: '请选择' }],
                },
              ]"
              placeholder="请选择"
            ></a-select>
          </a-form-item>
          <a-form-item label="服务名称">
            <a-input
              v-decorator="[
                'opsName',
                {
                  rules: [{ required: true, message: '请输入' }],
                },
              ]"
              placeholder="请输入服务名称"
            ></a-input>
          </a-form-item>

          <div class="grid grid-cols-3 gap-5">
            <a-form-item label="发布日期">
              <a-date-picker v-decorator="['publishTime']" placeholder="请选择发布日期"></a-date-picker>
            </a-form-item>
          </div>
          <a-form-item label="场景描述">
            <a-textarea
              v-decorator="['sceneNote']"
              :autoSize="{ minRows: 10, maxRows: 15 }"
              placeholder="请输入场景描述"
            ></a-textarea>
          </a-form-item>
          <a-form-item label="请求地址">
            <a-input
              v-decorator="[
                'serviceUrl',
                {
                  rules: [{ required: true, message: '请输入' }],
                },
              ]"
              placeholder="请输入请求地址"
            ></a-input>
          </a-form-item>
          <a-form-item label="请求方式">
            <a-select
              class="form-control"
              v-decorator="[
                'serviceType',
                {
                  rules: [{ required: true, message: '请选择' }],
                },
              ]"
              :options="serviceTypeList"
              placeholder="请选择请求方式"
            ></a-select>
          </a-form-item>
          <a-form-item label="登记json">
            <JsonEditor :json="json" style="width: 100%; height: 500px" @change="editorChange"></JsonEditor>
            <div>
              <div style="color: #f5222d" v-if="errorMsg">{{ errorMsg }}</div>
              <div class="mt-5" v-else>
                <div class="flex justify-end gap-2">
                  <a-button type="primary" shape="circle" @click="addRow">
                    <a-icon type="plus-circle"></a-icon>
                  </a-button>
                  <a-button
                    type="primary"
                    shape="circle"
                    ghost
                    :disabled="selectedRowKeys.length === 0"
                    @click="minusRow"
                  >
                    <a-icon type="minus-circle"></a-icon>
                  </a-button>
                </div>
                <a-table
                  :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                  class="mt-2"
                  :dataSource="json"
                  :columns="columns"
                  size="small"
                  :pagination="false"
                ></a-table>
              </div>
            </div>
          </a-form-item>

          <a-form-item label="在线文档">
            <a-input v-decorator="['fileUrl']" placeholder="请输入文档地址"></a-input>
          </a-form-item>
          <!-- 审核才需要显示状态 -->
          <a-form-item label="状态" v-if="type === 'audit'">
            <a-radio-group
              v-decorator="[
                'status',
                {
                  rules: [{ required: true, message: '请选择' }],
                },
              ]"
              :options="typesMap.status"
            ></a-radio-group>
          </a-form-item>
        </div>
      </a-form>

      <div class="detailView-actions">
        <a-button class="app-btn condition-btns" type="primary" @click="doSave">保存</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getOpsLineList, getOpsTypeList } from '@/service/getData'
import { getTypeName, safeSetFieldsValue } from '@/utils'
import JsonEditor from '@/components/JsonEditor'
import { validate } from 'jsonschema'
import moment from 'moment'

export default {
  components: {
    JsonEditor,
  },
  setup() {
    const typesMap = {
      status: [
        // {
        //   label: '未验证',
        //   value: 1,
        // },
        {
          label: '可用',
          value: 2,
        },
        {
          label: '无效',
          value: 3,
        },
      ],
    }
    return {
      typesMap,
      getTypeName,
    }
  },
  data() {
    return {
      form: this.$form.createForm(this),
      opsLineList: [],
      opsTypeList: [],
      json: [{ fieldName: '', fieldDesc: '', example: '' }],
      selectedRowKeys: [],
      schema: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            fieldName: {
              type: 'string',
            },
            fieldDesc: {
              type: 'string',
            },
            example: {
              type: 'string',
            },
          },
          required: ['fieldName', 'fieldDesc', 'example'],
        },
      },
      columns: [
        {
          title: '参数',
          dataIndex: 'fieldName',
          customRender: (text, row) => {
            return (
              <div>
                <a-input v-model={row.fieldName} />
              </div>
            )
          },
        },
        {
          title: '字段说明',
          dataIndex: 'fieldDesc',
          customRender: (text, row) => {
            return (
              <div>
                <a-input v-model={row.fieldDesc} />
              </div>
            )
          },
        },
        {
          title: '输入案例',
          dataIndex: 'example',
          customRender: (text, row) => {
            return (
              <div>
                <a-input v-model={row.example} />
              </div>
            )
          },
        },
      ],
      errorMsg: '',
      serviceTypeList: [
        {
          label: 'post',
          value: 'post',
        },
        {
          label: 'get',
          value: 'get',
        },
        {
          label: 'put',
          value: 'put',
        },
        {
          label: 'delete',
          value: 'delete',
        },
      ],
    }
  },
  computed: {
    type() {
      return this.$route.query.type
    },
  },
  methods: {
    async loadById(id) {
      const res = await this.$axios.post('/ops-server/opsTemplate/get', {
        id,
      })
      const detailData = res.data
      this.json = JSON.parse(detailData.registerJson)
      this.onSelectOpsLine(res.data.lineCode)
      safeSetFieldsValue(this.form, { ...detailData, publishTime: moment(detailData.publishTime) })
    },
    doSave() {
      //
      this.form.validateFieldsAndScroll(async (errors, values) => {
        if (!errors) {
          if (this.errorMsg) {
            return
          }
          for (let i = 0; i < this.json.length; i++) {
            if (!this.json[i].fieldName) {
              return this.$message.error('登记json参数不能为空')
            }
            if (!this.json[i].fieldDesc) {
              return this.$message.error('登记json字段说明不能为空')
            }
            if (!this.json[i].example) {
              return this.$message.error('登记json输入案例不能为空')
            }
          }
          const params = values
          try {
            const data = {
              id: this.$route.query.id,
              ...params,
              publishTime: params.publishTime ? params.publishTime.format('YYYY-MM-DD HH:mm:ss') : '',
              registerJson: JSON.stringify(this.json),
            }
            if (!this.$route.query.id) {
              await this.$axios.post('/ops-server/opsTemplate/add', data)
            } else {
              await this.$axios.post('/ops-server/opsTemplate/put', data)
            }

            this.$message.success('操作成功!')
            this.$router.go(-1)
          } catch (e) {
            console.error(e)
          }
        }
      })
    },
    editorChange(e, json) {
      if (!e) {
        const result = validate(json, this.schema)
        if (!result.errors.length) {
          this.json = json
          this.errorMsg = ''
        } else {
          this.errorMsg = '登记json格式为[{fieldName,fieldDesc,example}]'
        }
      } else {
        this.errorMsg = 'json格式错误'
      }
    },
    addRow() {
      this.json.push({ fieldName: '', fieldDesc: '', example: '' })
    },
    minusRow() {
      this.json = this.json.filter((e, index) => {
        return !this.selectedRowKeys.includes(index)
      })
      this.selectedRowKeys = []
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    async onSelectOpsLine(lineCode) {
      this.form.setFieldsValue({ typeCode: undefined })
      this.opsTypeList = await getOpsTypeList(lineCode)
    },
  },
  async mounted() {
    this.opsLineList = await getOpsLineList()
    const id = this.$route.query.id
    if (id) {
      this.loadById(id)
    }
  },
}
</script>

<style></style>
